// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* Base URL for Dev environment */
// const basePath = 'https://electrificationapi-ams-dev-01.azure-api.net/common/api';
// const baseNotificationPath = 'https://electrificationapi-ams-dev-01.azure-api.net/notification/api';

const basePath =
  "https://electrificationapi-ams-test-01.azure-api.net/common/api";
const baseNotificationPath =
  "https://electrificationapi-ams-test-01.azure-api.net/notification/api";

export const environment = {
  basePath: basePath,
  production: true,
  disableConsoleLog: "false",
  refreshToken: "refreshToken",
  buildNo: "#2020111.1",
  expiryTime: "expiryTime",
  accessToken: "accessToken",
  currentUser: "currentUser",
  clientId: "29fb0579-6eec-4734-af46-3e94310e05c9",
  tenantId: "372ee9e0-9ce0-4033-a64a-c07073a91ecd",
  scopeId: "api://6e70d1d5-3c48-4cad-8e32-165ffb80c922/elbackend",
  redirectURL: "http://localhost:4200/", //  'https://dev.electrification.ability.abb/',
  baseURL: "http://localhost:8841/", // 'https://dev.electrification.ability.abb/',
  // subscriptionKey: '367c9ca318744c26be742a005ed796dd',
  subscriptionKey: "69b9a7861fae4c05ae11a763586693a8", // Enable- when points to test
  plantsURL: `${basePath}/plants`,
  plantURL: `${basePath}/plant`,
  assetsURL: `${basePath}/devices`,
  organizationURL: `${basePath}/organization`,
  organizationInvitation: `${basePath}/organizationInvitation`,
  organizationUserURL: `${basePath}/organizationUser`,
  organizationUserSiteURL: `${basePath}/organizationSite`,
  settingsUrl: `${basePath}`,
  notificationUrl: `${baseNotificationPath}/plant`,
  releaseManagementUrl: `${basePath}/release/management`,
  bingMapKey:
    "AktdM1vYVexBV_ML4B2m_Tnqd0vQVk5C0YvCX_7EJutqK6EhV6YdpeCdOv6yd7-J",
  apiDocumentLink:
    "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107991A2658&LanguageCode=en&DocumentPartId=&Action=Launch",
  feedback:
    "https://dev.partnerhub.connect.abb.com/IdeaTank/list-admin?openModal=true&sourceId=1&reasonId=2&productId=1851&productDetailId=0&productVersionId=0",
  authAPI: "https://electrificationapi-ams-dev-01.azure-api.net/oauth/api/",
  basePathURL: basePath,
  deviceImageUrl: "assets/devices/",
  reportDownloadPathURL: `https://abb.staging.activeenergy.ie`,
  contactUsEmail: `global-el.operations.digital@abb.com`,
  pivotURL: "https://test.abb.tallarna.com/login",
  energySimulatorURL: "https://energysimulator.sitemanager.ability.abb/",
  eSCOCompleteMatchingUrl: "https://dash.wattics.com/breakdowns",
  eSCOAdvancedSavingUrl: "https://dash.wattics.com/breakdowns",
  escoEnv: "row-test",
  enableMatching: "true",
  marketplacelink:"https://stage.marketplace.ability.abb/s/?language=en_US",
  isChinaProd: "false",
  msal: {
    solution: "el.iam.region",
    tenant: "elcp-iam-test-tenant",
    b2C: "abilityrel03eunbdv",
    scope: "region",
    clientId: "40a44fd9-002f-4166-847f-4e69b2e45aa6",
  },
  wave2SiteUrl: "https://test.new.sitemanager.ability.abb",
  wave2ApiUrl:
      "https://electrificationapiv2-ams-test-01.azure-api.net/common-api/api",
    wave2ApiSubscriptionKey: "4883ae0cf2e044e5a5663c4edeb00f4a",
    version: "#{version}#",
    enableSiteSwitch: false,
    versionUrl: "https://electrificationapi-ams-test-01.azure-api.net/",

    isChinaB2C: false,

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

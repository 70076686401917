import { Injectable} from '@angular/core';
import { HttpClient,HttpHeaders,HttpXhrBackend} from '@angular/common/http'; 
import { Observable,of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
@Injectable()
export class VersionService {
  static instance: VersionService;
  static getInstance() {
    if (VersionService.instance == null) {
        VersionService.instance = new VersionService ();
    }
    return VersionService.instance;
  }
  constructor() {
 }
  public getVersion(): Observable<any> { 
      const headerOptons = new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
          'Ocp-Apim-Subscription-Key': localStorage.getItem('Key')
        });
        let  versionURL= localStorage.getItem('versionURL');
        const url = `${versionURL}version`;
        const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
        return httpClient.get(url, { headers: headerOptons,responseType: 'text' }).pipe(
          map((response:any) => {
            return response;
          }),
           /*** MARK: US-212065 by Mister - Filter get version api,becaues it will make the page dispaly error ***/
          catchError((error) => {
            console.log(error);
            return of();
          })
           /*** END MARK***/
        );
  } 
}